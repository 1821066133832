import React, { useEffect } from 'react';

import { navigate } from 'gatsby';
import Layout from 'components/Layout';
import EventCardAnimation from 'components/EventCardAnimation';

function OauthCallback() {
  useEffect(() => {
    navigate('/');
  }, []);
  return (
    <Layout>
      <EventCardAnimation />
    </Layout>
  );
}

export default OauthCallback;
